<template>
  <div>
    <div v-if="type === 'localTransfer'" class="btn__grid" @click="setAmount">
      <button
        type="button"
        class="btn btn__black--two"
        @click="form.amount = 1000"
      >
        1,000
      </button>
      <button
        type="button"
        class="btn btn__black--two"
        @click="form.amount = 5000"
      >
        5,000
      </button>
      <button
        type="button"
        class="btn btn__black--two"
        @click="form.amount = 10000"
      >
        10,000
      </button>
      <button
        type="button"
        class="btn btn__black--two"
        @click="form.amount = 20000"
      >
        20,000
      </button>
      <button
        type="button"
        class="btn btn__black--two"
        @click="form.amount = 50000"
      >
        50,000
      </button>
      <button
        type="button"
        class="btn btn__black--two"
        @click="form.amount = 100000"
      >
        100,000
      </button>
      <button
        type="button"
        class="btn btn__black--two"
        @click="form.amount = 500000"
      >
        500,000
      </button>
      <button
        type="button"
        class="btn btn__black--two"
        @click="form.amount = 1000000"
      >
        1,000,000
      </button>
    </div>

    <div v-if="type === 'bills'" class="btn__grid" @click="setAmount">
      <button
        type="button"
        class="btn btn__black--two"
        @click="form.amount = 500"
      >
        500
      </button>
      <button
        type="button"
        class="btn btn__black--two"
        @click="form.amount = 1000"
      >
        1,000
      </button>
      <button
        type="button"
        class="btn btn__black--two"
        @click="form.amount = 5000"
      >
        5,000
      </button>
      <button
        type="button"
        class="btn btn__black--two"
        @click="form.amount = 10000"
      >
        10,000
      </button>
      <button
        type="button"
        class="btn btn__black--two"
        @click="form.amount = 20000"
      >
        20,000
      </button>
      <button
        type="button"
        class="btn btn__black--two"
        @click="form.amount = 50000"
      >
        50,000
      </button>
      <button
        type="button"
        class="btn btn__black--two"
        @click="form.amount = 100000"
      >
        100,000
      </button>
      <button
        type="button"
        class="btn btn__black--two"
        @click="form.amount = 500000"
      >
        500,000
      </button>
    </div>

    <div v-if="type === 'airtimeData'" class="btn__grid" @click="setAmount">
      <button
        type="button"
        class="btn btn__black--two"
        @click="form.amount = 100"
      >
        100
      </button>
      <button
        type="button"
        class="btn btn__black--two"
        @click="form.amount = 200"
      >
        200
      </button>
      <button
        type="button"
        class="btn btn__black--two"
        @click="form.amount = 500"
      >
        500
      </button>
      <button
        type="button"
        class="btn btn__black--two"
        @click="form.amount = 1000"
      >
        1,000
      </button>
      <button
        type="button"
        class="btn btn__black--two"
        @click="form.amount = 1500"
      >
        1,500
      </button>
      <button
        type="button"
        class="btn btn__black--two"
        @click="form.amount = 2500"
      >
        2,500
      </button>
      <button
        type="button"
        class="btn btn__black--two"
        @click="form.amount = 5000"
      >
        5,000
      </button>
      <button
        type="button"
        class="btn btn__black--two"
        @click="form.amount = 10000"
      >
        10,000
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["type"],
  data() {
    return {
      form: {
        amount: ""
      }
    };
  },
  methods: {
    setAmount() {
      if (this.form.amount !== "") {
        this.$emit("selectedAmount", this.form.amount);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
</style>
